// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// IMPORT BARBA
import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

document.addEventListener("turbolinks:load", () => {

  //barba page transitions
  barba.init({
    debug: true,
    preventRunning: true,

    transitions: [
      {
        name: 'slide',

        leave(data) {

          // Scroll to top, wait for it to finish
          if (window.scrollY > 0) {
            return new Promise(resolve => {
              const timeline = gsap.timeline({
                onComplete() {
                  resolve()
                }
              })

              timeline
                .to(window, {duration: 0.5, scrollTo: 0, ease: "power1.inOut"});
            })
          }

        },

        enter(data) {

          console.log('enter')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.next.container, {y: "100vh"}, {y: "0%", duration: 0.4, ease: "power3"}, 0)
          })

        },

        beforeEnter() {},

        afterEnter() {

          // Wrap the menu in a link
          $( "main" ).wrap( "<a href='/' class='menuLinkWrapper'></a>" );

          // Fix menu for scrolling
          $('main').css('position', 'fixed')

          enablePanelPeak()

        },

        beforeLeave({ current, next, trigger }) {

          console.log('beforeLeave')

        },

      },

      {
  			name: "slideHome",

        leave(data) {

          console.log('leave')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .to(data.current.container, {y: "100vh", duration: 0.4}, 0)
          })

        },

        to: {
          namespace: [
            'home'
          ]
        },
      },

      {
  			name: "slideEvents",

        leave(data) {

          // Scroll to top, wait for it to finish
          if (window.scrollY > 0) {
            return new Promise(resolve => {

              // Create a faux what's on banner
              var fauxEvents = document.createElement("div");
              fauxEvents.innerHTML = "WHAT'S ON";

              fauxEvents.innerHTML == $("#archiveFlag").innerHTML
              console.log(fauxEvents)

              fauxEvents.classList.add('fauxEvents')
              document.body.appendChild(fauxEvents);
              $( ".fauxEvents" ).wrap( "<a href='/events' class='fauxEventsWrapper'></a>" );

              const timeline = gsap.timeline({
                onComplete() {
                  resolve()
                }
              })

              timeline
                .to(window, {duration: 0.25, scrollTo: 0, ease: "power1.in"});
            })
          }

        },

        enter(data) {

          console.log('enter')

          return new Promise(resolve => {

            // Hold onto old container
            var holder = data.current.container
            document.body.appendChild(holder);

            const timeline = gsap.timeline({
              onComplete() {
                resolve()

                // Remove the faux banners
                var fauxEvents = $('.fauxEvents').detach();
                var fauxEventsWrapper = $('.fauxEventsWrapper').detach();
              }
            })

            timeline
              .fromTo(data.current.container, {y: "0vh"}, {y: "100vh", duration: 0.4}, 0)
          })

        },

        from: {
          namespace: [
            'event'
          ]
        },

        to: {
          namespace: [
            'whatson'
          ]
        },
      },

      {
  			name: "slideEventsHome",

        leave(data) {

          console.log('leave')

          return new Promise(resolve => {

            // Create a faux what's on banner
            var fauxEvents = document.createElement("div");


            fauxEvents.innerHTML = "WHAT'S ON";


            fauxEvents.classList.add('fauxEvents')
            document.body.appendChild(fauxEvents);
            $( ".fauxEvents" ).wrap( "<a href='/events' class='fauxEventsWrapper'></a>" );

            const timeline = gsap.timeline({
              onComplete() {
                resolve()

                // Remove the faux banners
                var fauxEvents = $('.fauxEvents').detach();
                var fauxEventsWrapper = $('.fauxEventsWrapper').detach();
              }
            })

            timeline
              .fromTo(data.current.container, {y: "0vh"}, {y: "100vh", duration: 0.4}, 0)
              .fromTo('.fauxEvents', {y: "0vh"}, {y: "100vh", duration: 0.4}, 0)
          })

        },

        from: {
          namespace: [
            'event'
          ]
        },

        to: {
          namespace: [
            'home'
          ]
        },
      },

      {
  			name: "slideBetweenAreas",

        leave(data) {

          console.log('leave')

          return new Promise(resolve => {

            // Create a faux what's on banner
            var fauxEvents = document.createElement("div");
            fauxEvents.classList.add('fauxEvents')
            document.body.appendChild(fauxEvents);
            $( ".fauxEvents" ).wrap( "<a href='/events' class='fauxEventsWrapper'></a>" );

            // Remove the drop shadow
            data.current.container.classList.add('removeDropShadow')
            data.next.container.classList.add('removeDropShadow')

            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.current.container, {x: "0vw"}, {x: "100vw", duration: 0.6}, 0)
          })

        },

        enter(data) {

          console.log('leave')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()

                // Remove the faux banners
                var fauxEvents = $('.fauxEvents').detach();
                var fauxEventsWrapper = $('.fauxEventsWrapper').detach();

                // Add a drop shadow
                data.next.container.classList.remove('removeDropShadow')
              }
            })

            timeline
              .fromTo(data.next.container, {x: "-100vw"}, {x: "0vw", duration: 0.6}, 0)
          })

        },

        from: {
          namespace: [
            'area'
          ]
        },

        to: {
          namespace: [
            'area'
          ]
        },
      },

      {
  			name: "slideEventfromHome",

        enter(data) {

          console.log('enter')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.next.container, {y: "100%"}, {y: "0vh", duration: 0.4, ease: "power3"}, 0)
              .fromTo('.fauxEvents', {y: "100%"}, {y: "0vh", duration: 0.4, ease: "power3"}, 0)
          })

        },

        beforeEnter() {

          console.log('beforeEnter')

        	// reset page positioning
        	window.scrollTo({
        		top: 0,
        		behavior: "auto"
        	});

        },

        afterEnter() {

          // Wrap the menu in a link
          $( "main" ).wrap( "<a href='/' class='menuLinkWrapper'></a>" );

          // Fix menu for scrolling
          $('main').css('position', 'fixed')

          enablePanelPeak()

        },

        beforeLeave({ current, next, trigger }) {

          console.log('beforeLeave')

        },

        from: {
          namespace: [
            'home'
          ]
        },

        to: {
          namespace: [
            'event'
          ]
        },
      }
    ],

    views: [

      {
        namespace: "home",

        beforeEnter() {

          // Position menu for interaction
          $('main').css('position', 'relative')

          // Unwrap all links
          checkMainWrapper()
          function checkMainWrapper() {
            if ($('main').parent().is('.menuLinkWrapper')) {
              $('main').unwrap()
              checkMainWrapper()
            }
          }

          // Slide up message bar
          $( ".flashWrapper" ).delay( 3000 ).slideUp( 300 )

        }
      },

      {
        namespace: "event",

        afterEnter() {

          // Create a faux what's on banner
          var fauxEvents = document.createElement("div");
          fauxEvents.innerHTML = $("#archiveFlag").text().trim()
          fauxEvents.classList.add('fauxEvents')
          document.body.appendChild(fauxEvents);
          console.log($(fauxEvents).text().trim())
          if ($(fauxEvents).text().trim() == "ARCHIVE") {
            $( ".fauxEvents" ).wrap( "<a href='/archive' class='fauxEventsWrapper'></a>" );
          } else {
            $( ".fauxEvents" ).wrap( "<a href='/events' class='fauxEventsWrapper'></a>" );
          }

          $( ".eventDescription a" ).each(function(){
            $(this).attr('target', '_blank');
          })

        },

        beforeLeave() {

          // Move the faux banner to sit inside the section
          var fauxEvents = $('.fauxEvents').detach();

        }

      },

      {
        namespace: "faqs",

        beforeEnter() {

          loadFAQs()

        }
      },

      {
        namespace: "area",

        beforeEnter() {

          // Set links to blank
          $(".description a").each(function() {
            $(this).attr('target', '_blank');
          })

          // Set email subject to page title
          var pageTitle = $('.areaPageTitle').html()
          $('input#message_subject').val(pageTitle + " Enquiry")

        }
      },

      {
        namespace: "whatson",

        beforeEnter() {

          // Mobile filter open
          mobileFilterClick()
          function mobileFilterClick() {
            if (window.innerWidth < 776) {
              $('.areas p').click(function(){
                $('.areasList').slideToggle()
              })
            }
          }

          // Filter based on area
          $('.areaIcon span').click(function(){

            $('.areaIcon span').removeClass('underline')
            var areaName = $(this).attr('class');
            $(this).addClass('underline')

            $('.eventLink').each(function(){
              if ($(this).hasClass(areaName) == true) {
                $(this).removeClass('hide')
              } else {
                $(this).addClass('hide')
              }
            })
          })

        }
      },

      {
        namespace: "drinks",

        beforeEnter() {

          // Animate in menu images
          $('img.menu').each(function(index) {
            var random = Math.random()*4 - 2;
            $(this).css('transform', 'rotate(' + random + 'deg)')
            $(this).delay(100 * index).animate({
              opacity: 1,
              marginTop: 20
            }, 200)
          })

          $('img.menu').mouseenter(function(){
            var random = Math.random()*4 - 2;
            $(this).css('transform', 'rotate(' + random + 'deg)')
          })

        }
      }

    ]
  })

  // Acknowledge country popup
  $('.country').click(function(){
    $(this).slideUp(200);
    $('.blur').css('backdrop-filter', 'blur(0px)');
  })

  // Change placeholder text of email subscription
  setTimeout(function(){
    $('#OztixSubscribe_EmailAddress_Input').attr('placeholder', 'MAILING LIST SIGN UP')
    $('#OztixSubscribe_Submit_Button').attr('value', '\u261D')
  }, 10);

  // Wrap the menu in a link
  $( "main" ).wrap( "<a href='/' class='menuLinkWrapper'></a>" );

  // Load in FAQs on page
  function loadFAQs() {
    // Define a trackable array
    const faqsOpen = new Array()
    var faqIndex
    // When a question is clicked
    $('.faq .question').click(function() {
      faqIndex = $('.faq .question').index(this)
      $('.faq .answer').slideUp('fast')
      $('.faq .question').each(function(index) {
        if (index == faqIndex) {
          return
        } else {
          faqsOpen[index] = false
        }
      })
      if (faqsOpen[faqIndex] == false || faqsOpen[faqIndex] == undefined) {
        $(this).siblings('.answer').slideDown('fast')
        faqsOpen[faqIndex] = true
      } else {
        faqsOpen[faqIndex] = false
      }
    })
  }

  // Panel peak function
  enablePanelPeak()
  function enablePanelPeak() {
    // $('.menuLinkWrapper').hover(function() {
    //   $('section.panel').animate({ "top": "+=10px" }, 160 );
    // }, function() {
    //   $('section.panel').animate({ "top": "-=10px" }, 160 );
    // })
  }


})
